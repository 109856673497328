div.ReactTags__tags {
  position: relative;
  display: inline-block;
}

/* Styles for the input */

div.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
  margin-left: 10px;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  margin: 0;
  font-size: 16px;
  width: 100%;
  padding: 7px 10px 6px 8px;
  border: none;
  outline: none;
  background: transparent;
  color: var(--text);
}

input.ReactTags__tagInputField:hover + .ReactTags__tags2 {
  border: none;
}

.tag-wrapper.ReactTags__tag > button {
  border: none;
  margin-left: 6px;
  color: var(--text);
  background: transparent;
  font-size: 14.77px;
}

.tag-wrapper.ReactTags__tag > button:focus {
  outline: none;
}

.tag-wrapper.ReactTags__tag {
  font-size: 16px;
  font-weight: 400;
}

/* Styles for selected tags */

div.ReactTags__selected span.ReactTags__tag {
  border: none;
  /* border: 1px solid var(--onsurfacelow); */
  box-sizing: border-box;
  background: var(--onsurfacelowest);
  border-radius: 7px;
  font-size: 16px;
  padding: 5px 10px 4px 8px;
  margin: 0 5px 5px;
  cursor: default !important;
  /* overflow: scroll; */
  display: inline-block;
  position: relative;
  width: auto;
  color: var(--text) !important;
}
div.ReactTags__selected span.ReactTags__tag.redtag {
  border: 1px solid var(--error) !important;
  color: var(--error) !important;
  background-color: #f4dbdf;
  height: 33px;
}
div.ReactTags__selected span.ReactTags__tag.redtag > button {
  color: var(--error) !important;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #9c9c9c;
  margin-left: 5px;
  margin-top: 2px;
  cursor: pointer;
}

/* Styles for suggestions */

div.ReactTags__suggestions {
  position: absolute;
  color: #000 !important;
  font-weight: normal !important;
  font-size: 14px !important;
  z-index: 10000;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 100%;
  max-width: 500px;
  padding-left: 0;
  margin-left: 0;
  font-family: "Roboto", sans-serif;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__tags > div.ReactTags__selected {
  /*display: flex;*/
  overflow-y: auto;
  padding-bottom: 0;
  height: auto;
  max-height: 110px;
}

.ReactTags__tags2.react-tags-wrapper:focus-within {
  color: var(--onsurfacehighest-text);
  box-shadow: 0 0 0 3px #fddf7f;
  border-color: var(--primary);
}

div.ReactTags__tags {
  position: relative;
  display: flex;
  max-width: calC(100vw - 470px);
  border: none;
  height: auto;
  max-height: 150px;
  min-height: 48px;
  align-items: center;
}

.contacts_badges {
  max-width: 100%;
  display: flex;
  align-items: center;
}

div.ReactTags__tags ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent;
}

div.ReactTags__tags ::-webkit-scrollbar-thumb {
  background: #999;
  width: 2px;
  border-radius: 6px;
  background-color: #999999;
}

.ReactTags__tags2 > div.ReactTags__selected {
  /*display: flex;*/
  overflow-y: auto;
  padding-bottom: 2px;
  height: 30px;
}

div.ReactTags__tags2 {
  position: relative;
  display: flex;
  max-width: calC(100vw - 470px);
  border-radius: 11px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  padding: 5px 0 0 5px;
  height: 38px;
}

div.ReactTags__tags2 ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent;
}

div.ReactTags__tags2 ::-webkit-scrollbar-thumb {
  background: #999;
  width: 2px;
  border-radius: 6px;
  background-color: #999999;
}

div.ReactTags__tags3 {
  position: relative;
  display: flex;
  max-width: calC(100vw - 470px);
  border-radius: 11px;
  border: 1px solid var(--onsurfacelow);
  background-color: var(--surface);
  padding: 8px 0 8px 5px;
  /* height: 38px; */
  max-width: inherit;
  /* overflow-y: auto; */
}

div.ReactTags__tags3 ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent;
}

div.ReactTags__tags3 ::-webkit-scrollbar-thumb {
  background: #999;
  width: 2px;
  border-radius: 6px;
  background-color: #999999;
}

.ReactTags__tags3:focus-within {
  color: var(--onsurfacehighest-text);
  box-shadow: 0 0 0 3px #fddf7f;
  border-color: var(--primary);
}

.ReactTags__tagInputField::placeholder {
  color: #999999;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: left;
}

.ReactTags__tagInputField2::placeholder {
  color: var(--onsurfacehigh-text);
}

.message-span {
  color: var(--text);
  text-align: left;
  font-weight: "normal";
  flex: 12;
  font-size: 16px;
  margin-top: 5px;
}

.resize-top-row {
  height: auto;
  min-height: 60px;
  max-height: 150px;
}
.resize-right-sec {
  display: flex;
  align-items: self-start;
  align-content: space-between;
  flex-wrap: wrap-reverse;
  flex-flow: column;
  height: 100%;
}
.resize-right-sec .resize-top-row {
  height: auto;
  width: 100%;
  min-height: auto;
  max-height: 100%;
  align-items: center;
}
.resize-right-sec .chat_or_info {
  height: 100%;
  width: 100%;
}
.resize-right-sec .chat_bx_New.chat_bx {
  padding: 0;
  height: 100%;
}

.MuiDrawer-paperAnchorDockedRight {
  height: 100%;
}
.send_form ul.usr_list {
  overflow-y: auto;
  height: 100%;
}
.resize-top-row .message-span {
  margin-top: 0px;
}
.resize-top-row .top_right.d-flex {
  margin-top: 0px;
}
.left_arrow svg#newMessage-backArrow {
  margin-top: -5px;
}
.resize-right-sec .top_row.resize-top-row {
  padding: 8px 15px 8px;
}
.resize-top-row .top_right.d-flex {
  position: static !important;
}
.topEditBtn {
  display: none;
}
.topEditBtn a#show_info {
  height: 32px;
  width: 32px;
  margin-left: 0 !important;
}
.topEditBtn a#show_info svg {
  height: 16px;
  width: 16px;
}
@media (min-width: 992px) {
  .resize-right-sec .MuiDrawer-docked .MuiDrawer-paper {
    position: absolute;
    top: 0 !important;
    bottom: auto !important;
    width: 100% !important;
  }
}

@media (min-width: 1451px) {
  .ReactTags__selected {
    max-width: calc(100% - 20px);
  }
}

@media (min-width: 1571px) {
  .ReactTags__selected {
    max-width: calc(100% - 31px);
  }
}
  @media (max-width: 767px) {
  .topEditBtn {
    display: block;
}
}

